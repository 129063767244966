<template>
	<div>
		<div id="bind">
			<p>微信绑定</p>
			<img src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/bindwx.png" >
			<el-input
			  placeholder="请输入手机号"
			  v-model="input"
			  clearable>
			</el-input>
			<button type="button" @click="bindfun">点击绑定微信</button>
		</div>
		<div id="info" v-if="openid">
			<p>已绑定微信</p>
			<div id="info_box">
				<img :src="headimgurl" >
				<span>{{nickname}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	const axi =require('../../assets/axi.js')
	export default{
		data(){
			return{
				input:"",
				nickname:"",
				headimgurl:"",
				openid:""
			}
		},
		mounted() {
			console.log(window.location.href)
			let Url=window.location.href
			// let Url='http://www.rhdgj.com/?code=041jnmll2kyWX74XXmnl27mP744jnmlc&state=STATE#/WeChat'
			if(Url.split('?')[1]){
				let Url1=Url.split('?')[1]
				let Code=Url1.split('=')[1].split('&')[0]
				let Phone=Url1.split('#')[0].split('=')[2]
				this.input=Phone
				console.log(Phone)
				console.log(Code)
				axi.post('Agent/GetOpenidAndAccessTokenFromCodeSnsapi_userinfo?Phone='+Phone+'&Code='+Code).then((res)=>{
					if(res.data.Code==1000){
						console.log(res)
						let Date=JSON.parse(res.data.Dto)
						this.openid=Date.openid
						this.nickname=Date.nickname
						this.headimgurl=Date.headimgurl.replace('/\/','')
					}else{
						this.$message.error(res.data.Msg)
					}
					
				})
			}
			
		},
		methods:{
			bindfun(){
				if(this.input==''){
					this.$message.info('请输入绑定手机号')
					return
				}
				if(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.input)){
					window.location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc5a84247c3c9948f&redirect_uri=http%3A%2F%2Fwww.rhdgj.com%2F%23%2FWeChat&response_type=code&scope=snsapi_userinfo&state='+this.input+'#wechat_redirect'
				}else{
					this.$message.info('请输入正确的手机号')
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	#bind{
		text-align: center;
		p{
			font-size: 36px;
			font-weight: bold;
			text-align: center;
			margin-top: 50px;
		}
		img{
			width: 60%;
		}
		.el-input{
			width: 90%;
			margin-bottom: 30px;
		}
		button{
			width: 50%;
			height: 50px;
			background-color: #fd3f31;
			color: #FFFFFF;
			border-radius: 15px;
		}
	}
	#info{
		margin-top: 30px;
		p{
			text-align: center;
			font-size: 22px;
			font-weight: bold
		}
		#info_box{
			width: 90%;
			margin-left: 5%;
			img{
				width: 60px;
				height: 60px;
				border-radius: 50%;
				margin-right: 30px;
			}
		}
	}
</style>
